import React, { useEffect, useState } from 'react';

import { useStoreon } from 'storeon/react';
import { Box } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { FormProvider, useForm } from 'react-hook-form';
import theme from '../../../style/themev2';

import Loading from '../../common/elements/Loading';
import Api from '../../../api/Api';
import Paginator from '../../common/table/Paginator';
import Filter from '../../filter';
import EventList from './EventList';
import useStyles from './styles';
import EmptyHistory from '../EmptyHistory';
import EventStepper from '../EventStepper';
import useRoles from 'legacy/hooks/useRoles';
const mnmxIoCommon = require('@conserv/mnmx-io-common');
const commonConstant = mnmxIoCommon.commonConstant;

function EventHistory({ setFab }) {
	const classes = useStyles();
	const { dispatch, eventProfiles } = useStoreon(
		'eventProfiles',
		'locations',
		'sensors',
		'spaces'
	);

	const { isUser } = useRoles();

	const methods = useForm({
		defaultValues: {
			Type: [],
			Location: [],
			Space: [],
			Sensor: [],
			Status: [],
			Comments: []
		}
	});

	const [events, setEvents] = useState([]);
	const [page, setPage] = useState(1);
	const [pages, setPages] = useState(1);
	const [total, setTotal] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [isFetchingData, setIsFetchingData] = useState(true);
	const [openStepper, setOpenStepper] = useState(false);
	const [isFiltered, setIsFiltered] = useState(false);
	const [sort, setSort] = useState({ orderBy: 'date', direction: 'desc' });

	const hasFilters = params =>
		params.Location.length > 0 ||
		params.Sensor.length > 0 ||
		params.Space.length > 0 ||
		params.Status.length > 0 ||
		params.Type.length > 0;

	const loadEvents = params => {
		setIsFetchingData(true);

		const { Comments, ...restOfParams } = params;

		Api.getEventList({
			...restOfParams,
			...sort,
			...(Comments.length > 0
				? { hasComment: Comments.includes('with comments') ? 'yes' : 'no' }
				: {})
		})
			.then(response => {
				setIsFiltered(hasFilters(params));
				setEvents(response.data.events);
				setPages(response.data.pages);
				setTotal(response.data.total);
			})
			.catch(() => {
				dispatch('notification/add', {
					message: 'There was an error loading the latest alerts',
					severity: 'error',
					variant: 'banner'
				});
			})
			.finally(() => {
				setIsLoading(false);
				setIsFetchingData(false);
			});
	};

	useEffect(() => {
		dispatch('navstate/update', { pageTitle: 'Events' });
		dispatch('eventprofiles/refresh');
		dispatch('locations/refresh');
		dispatch('sensors/refresh');
		dispatch('spaces/refresh');
	}, []);

	const onSubmit = data => {
		loadEvents({
			...data,
			page
		});
	};

	const handlePageChange = (ev, currentPage) => {
		setPage(currentPage);
	};

	const handleSort = newSort => {
		if (
			newSort.orderBy !== sort.orderBy ||
			newSort.direction !== sort.direction
		) {
			setSort(newSort);
		}
	};

	useEffect(() => {
		methods.handleSubmit(onSubmit)();
	}, [page, sort]);

	useEffect(() => {
		setFab(null);
	}, []);

	const shouldShowCreateEventButton = () => {
		return !isUser && eventProfiles?.list?.length === 0;
	};

	const getDescription = () => {
		if (shouldShowCreateEventButton()) {
			return "Let's create your first event to track.";
		}

		return 'No alert has been triggered yet.';
	};

	const handleClickNewEvent = () => {
		setOpenStepper(true);
	};

	const handleCancel = () => setOpenStepper(false);

	return (
		<ThemeProvider theme={theme}>
			<FormProvider {...methods}>
				<Box height='100%' display='flex' flexDirection='column'>
					<Filter loadAlerts={loadEvents} page={page} setPage={setPage} />
					{isLoading ? (
						<Loading />
					) : (
						<>
							{events.length === 0 && !isFiltered ? (
								<EmptyHistory
									description={getDescription()}
									showButton={shouldShowCreateEventButton()}
									onClickNewEvent={handleClickNewEvent}
								/>
							) : (
								<>
									<Box style={{ overflowY: 'auto', height: 'inherit' }}>
										<Box>
											<EventList
												events={events}
												onSort={handleSort}
												sort={sort}
												isFetchingData={isFetchingData}
											/>
										</Box>
									</Box>
									{!isFetchingData ? (
										<Box pt={3} pb={3} display='flex' justifyContent='center'>
											<Paginator
												onChange={handlePageChange}
												count={pages}
												total={total}
												pageSize={commonConstant.PAGE_SIZE_EFFECTIVELY_ALL}
												showFirstButton
												showLastButton
												inPage={page}
											/>
										</Box>
									) : null}
								</>
							)}
						</>
					)}
				</Box>
			</FormProvider>
			{openStepper && (
				<EventStepper onCancel={handleCancel} isOpen={openStepper} />
			)}
		</ThemeProvider>
	);
}

export default EventHistory;
