import React, { Component } from 'react';
import clsx from 'clsx';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Drawer, AppBar, Box } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { connectStoreon } from 'storeon/react';
import Routes from '../../Routes';
import SidebarContainer from './SidebarContainer';
import TopNavContents from './TopNavContents';
import Notification from '../events/legacy/Notification';
import Api from '../../api/Api';
import FloatingActionButton from './FloatingActionButton';
import ActionModal from '../actionmodals/ActionModal';
import CompleteProfileDialog from '../account/profile/CompleteProfileDialog';
import { withRouter } from 'react-router-dom';

const drawerOpenWidth = 128;
const drawerClosedWidth = 128;

let prevPath = null;

const useStyles = theme => ({
	mainContent: {
		position: 'fixed',
		width: `calc(100% - ${drawerClosedWidth}px)`,
		marginTop: 56,
		marginLeft: drawerClosedWidth,
		height: `calc(100% - ${theme.spacing(7)}px)`,
		overflow: 'auto',
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeIn,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	drawer: {
		width: drawerOpenWidth,
		background: 'primary',
		borderRight: 0,
		height: '100%',
		position: 'fixed'
	},
	drawerContent: {
		bottom: 0,
		height: `calc(100% - ${64}px)`
	},
	drawerPaper: {
		zIndex: 100
	},
	rightDrawerPaper: {
		zIndex: 100,
		marginTop: '64px'
	},
	rightDrawer: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	appBar: {
		width: `calc(100% - 128px)`,
		height: 56,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeIn,
			duration: theme.transitions.duration.enteringScreen
		}),
		boxShadow: 'none',
		zIndex: 0
	},
	banner: {
		position: 'absolute',
		top: 64,
		right: 0,
		width: `auto`,
		paddingRight: '20px',
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeIn
		}),
		zIndex: 10
	},
	bannerShift: {
		width: `calc(100% - ${drawerOpenWidth}px)`,
		marginLeft: drawerOpenWidth,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	fullAv: {
		marginLeft: theme.spacing(1),
		marginTop: theme.spacing(1.5),
		marginBottom: theme.spacing(1.5),
		width: `calc(${drawerOpenWidth - theme.spacing(2) + 1}px)`,
		borderRadius: 0
	},
	squareAv: {
		marginLeft: theme.spacing(1),
		marginTop: theme.spacing(1.5),
		marginBottom: theme.spacing(1.5),
		width: theme.spacing(5),
		zIndex: -1
	},
	subscription: {
		marginLeft: theme.spacing(2),
		marginTop: theme.spacing(2),
		width: `calc(${drawerOpenWidth - theme.spacing(4) + 1}px)`
	},
	topItem: {
		marginLeft: theme.spacing(5),
		textTransform: 'capitalize'
	},
	filterForm: {
		marginLeft: theme.spacing(1),

		minWidth: 100
	},
	menuIcon: {
		paddingRight: theme.spacing(1)
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		marginRight: theme.spacing(2),
		marginLeft: theme.spacing(5),
		width: theme.spacing(5)
	},
	inputRoot: {
		color: 'inherit'
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '20ch'
		}
	}
});

class Nav extends Component {
	constructor(props) {
		super(props);
		this.state = {};

		this.props.history.listen(location => {
			if (location.pathname !== prevPath && window && window.analytics) {
				prevPath = location.pathname;
				window.analytics.page();
			}
		});
	}

	componentDidMount() {
		this.props.dispatch('customer/refresh');
		this.props.dispatch('users/refresh');
		this.props.dispatch('sensors/refresh');
		this.props.dispatch('customers/refresh');
		Api.getJoinableCustomers()
			.then(joinable => {
				this.setState({ customers: joinable.data });
			})
			.catch(() => {
				this.setState({
					alert: {
						iserror: true,
						title: 'Error Loading Customers',
						message:
							'There was an error loading your list of joinable customers'
					}
				});
			});
	}

	componentDidUpdate(prevProps) {
		if (
			this.props.navState.drawerOpen !== prevProps.navState.drawerOpen &&
			this.props.navState.onAfterDrawerClose
		) {
			this.props.navState.onAfterDrawerClose();
			this.props.dispatch('navstate/update', {
				onAfterDrawerClose: null
			});
		}
	}

	setFab = options => {
		this.setState({ fabOptions: options });
	};

	render() {
		const { classes } = this.props;
		return (
			<div>
				<DndProvider backend={HTML5Backend}>
					<AppBar position='fixed' className={classes.appBar}>
						<TopNavContents
							handleFilterClick={this.handleFilterClick}
							handleFilterClose={this.handleFilterClose}
							setFilter={this.setFilter}
							user={this.props.user}
							customer={this.props.customer}
							customers={this.state.customers}
						/>
					</AppBar>
					<div className={classes.banner}>
						<Notification notifications={this.props.notifications} />
					</div>
					<Box className={classes.drawer}>
						<SidebarContainer
							user={this.props.user}
							customer={this.props.customer}
							customers={this.state.customers}
							setFab={this.setFab}
						/>
					</Box>
					<Box pt={2} className={classes.mainContent}>
						<Routes publicOnly={false} setFab={this.setFab} />
					</Box>
					<Drawer
						classes={{ root: classes.rightDrawer }}
						variant='temporary'
						anchor='right'
						open={this.props.navState.drawerOpen}
						PaperProps={{ className: classes.drawerPaper }}
						ModalProps={{ BackdropProps: { invisible: true } }}
						onClose={() => {
							if (this.props.navState.onDrawerClose) {
								const canClose = this.props.navState.onDrawerClose();
								if (canClose) {
									this.props.dispatch('navstate/update', {
										drawerOpen: false,
										drawerContent: null
									});
								}
							} else {
								this.props.dispatch('navstate/update', {
									drawerOpen: false,
									drawerContent: null
								});
							}
						}}
					>
						<Box style={{ width: '560px' }}>
							{this.props.navState.drawerContent}
						</Box>
					</Drawer>
					<FloatingActionButton actions={this.state.fabOptions} />
					<ActionModal />
					<CompleteProfileDialog
						open={
							this.props.user &&
							(!this.props.user.first_name || !this.props.user.last_name)
						}
					/>
					{this.props.navState.dialogOpen
						? this.props.navState.dialogContent
						: null}
				</DndProvider>
			</div>
		);
	}
}

const ConnectedNav = connectStoreon(
	'navState',
	'customer',
	'customers',
	'user',
	'subscription',
	'preferences',
	withStyles(useStyles, { withTheme: true })(Nav)
);

export default withRouter(ConnectedNav);
