import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Logout from './components/Logout';
import { OrganizationUsers } from './components/organization/OrganizationUsers';
import Observations from './containers/Observations';
import SubscriptionLanding from './components/account/subscription/SubscriptionLanding';
import Loading from './components/common/elements/Loading';
import Admin from './containers/Admin';
import EventHistory from './components/events/EventHistory';
import EventSettings from './components/events/EventSettings';
import TreeMultiselect from '../components/TreeMultiselect/TreeMultiselect';
import data from '../components/TreeMultiselect/sampleData';

const Home = React.lazy(() => import('./containers/Home'));
const Analytics = React.lazy(() => import('./containers/Analytics'));
const Analyticsv2 = React.lazy(() => import('../pages/Analytics'));
const Levels = React.lazy(() => import('./containers/Levels'));
// chunk loading is failing occasionally for observations?
// const Observations = React.lazy(() => import('./containers/Observations'));
const EventDetail = React.lazy(() => import('./containers/EventDetail'));
const ImportV2 = React.lazy(() => import('./containers/Import'));
const IPM = React.lazy(() => import('./containers/IPM'));
const Reports = React.lazy(() => import('./containers/Reports'));
const Settings = React.lazy(() => import('./containers/Settings'));
const Resources = React.lazy(() => import('./containers/Resources'));
const TabContainer = React.lazy(() =>
	import('./containers/setup/TabsContainer/TabContainer')
);
const RequestInfoPageComponent = React.lazy(() =>
	import('./containers/RequestInfoPageComponent')
);

// const Events = React.lazy(() => import('./containers/Events'));

export default function (props) {
	// clear out the fab button actions on route change, children will set if needed.
	return (
		<Suspense fallback={<Loading />}>
			<Switch>
				<Route path='/logout' exact render={rprops => <Logout {...rprops} />} />
				<Route
					path='/'
					exact
					render={rprops => <Home setFab={props.setFab} {...rprops} />}
				/>
				<Route
					exact
					path='/home'
					render={rprops => <Home setFab={props.setFab} {...rprops} />}
				/>
				<Route
					path='/event/history'
					exact
					render={rprops => <EventHistory setFab={props.setFab} {...rprops} />}
				/>
				<Route
					path='/settings'
					exact
					render={rprops => <Settings setFab={props.setFab} {...rprops} />}
				/>
				<Route component={EventDetail} exact path='/event/details/:uuid' />
				<Route path='/organization/users' exact component={OrganizationUsers} />
				<Route
					path='/sensor'
					exact
					render={rprops => <Home setFab={props.setFab} {...rprops} />}
				/>
				<Route
					path='/facility/:facilityid'
					exact
					component={rprops => <Home setFab={props.setFab} {...rprops} />}
				/>
				<Route
					path='/importv2'
					exact
					render={rprops => <ImportV2 setFab={props.setFab} {...rprops} />}
				/>
				<Route
					path='/setup/*'
					exact
					render={rprops => <TabContainer setFab={props.setFab} {...rprops} />}
				/>
				<Route
					path='/levels/:seriesuuid'
					exact
					render={rprops => <Levels setFab={props.setFab} {...rprops} />}
				/>
				<Route
					path='/observations'
					exact
					render={rprops => <Observations setFab={props.setFab} {...rprops} />}
				/>
				<Route
					path='/analytics'
					exact
					render={rprops => <Analytics setFab={props.setFab} {...rprops} />}
				/>
				<Route
					path='/analyticsv2'
					exact
					render={rprops => <Analyticsv2 setFab={props.setFab} {...rprops} />}
				/>
				<Route
					path='/ri'
					exact
					render={rprops => (
						<RequestInfoPageComponent setFab={props.setFab} {...rprops} />
					)}
				/>
				<Route
					path='/csplatform/admin'
					exact
					render={rprops => <Admin setFab={props.setFab} {...rprops} />}
				/>
				<Route
					path='/event/settings'
					exact
					render={rprops => <EventSettings setFab={props.setFab} {...rprops} />}
				/>
				<Route
					path='/resources'
					exact
					render={rprops => <Resources setFab={props.setFab} {...rprops} />}
				/>
				<Route
					path='/reports'
					exact
					render={rprops => <Reports setFab={props.setFab} {...rprops} />}
				/>
				<Route
					path='/ipm'
					exact
					render={rprops => <IPM setFab={props.setFab} {...rprops} />}
				/>
				<Route
					path='/landing/subscription/*'
					exact
					render={rprops => <SubscriptionLanding {...rprops} />}
				/>
				<Route
					path='/poc/tree-multiselect'
					exact
					render={rprops => (
						<div style={{ maxWidth: '40%' }}>
							<TreeMultiselect options={data} onChange={updated => {}} />
						</div>
					)}
				/>
				<Route path='*' component={() => <Redirect to='/' />} />
			</Switch>
		</Suspense>
	);
}
