import React, { Component } from 'react';
import { withCookies, Cookies, CookiesProvider } from 'react-cookie';
import { Box } from '@material-ui/core';
import { instanceOf } from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';
import { StoreContext } from 'storeon/react';
import { ThemeProvider as ThemeProviderV4 } from '@material-ui/core/styles';
import { ThemeProvider } from '@mui/material/styles';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { googleLogout } from '@react-oauth/google';
import dayjs from 'dayjs';
import Api from './api/Api';
import './App.css';
import 'react-vis/dist/style.css';
import Nav from './components/navigation/Nav';
import PublicRoutes from './PublicRoutes';
import withErrorHandler from './components/error/WithErrorHandler';
import PreferencesProvider from './providers/PreferencesProvider';
import PermissionProvider from './providers/PermissionProvider';
import themev4 from './style/theme';
import theme from '../styles/theme';
import store from './res/store';
import { importLocale } from './util/LocaleUtils';
import { createBrowserHistory } from 'history';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { diContainer } from '../inversify.config';
import { DI_TYPES } from '../diTypes';
import { Provider as InversifyProvider } from 'inversify-react';

import * as Sentry from '@sentry/react';

const history = createBrowserHistory();

Sentry.init({
	dsn: 'https://b0315e436790d07945772e59594defea@o4506719770640384.ingest.sentry.io/4506720192823296',
	integrations: [Sentry.reactRouterV5BrowserTracingIntegration({ history })],

	tracesSampleRate: 1,
	enabled: process.env.NODE_ENV === 'production'
});

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false
		}
	}
});

class App extends Component {
	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		this.state = {};
		this.state.loggedIn = false;
		this.state.navOnly = false;
		this.state.tokenExpiration = 0;
		this.urlParams = new URLSearchParams(window.location.search);
		this.state.queryparams = encodeURIComponent(window.location.search);
		this.state.pathname = encodeURIComponent(window.location.pathname);
		if (this.urlParams.get('no')) {
			this.state.navOnly = true;
		}
		this.setupBugSnag();
		console.log(
			`App.js: Starting at ${new Date()} with REACT_APP_LOG_INFO = ${
				process.env.REACT_APP_LOG_INFO
			}, REACT_APP_LOG_DEBUG = ${
				process.env.REACT_APP_LOG_DEBUG
			},  REACT_APP_LOG_TRACE = ${
				process.env.REACT_APP_LOG_TRACE
			},  LOG_INFO = ${process.env.LOG_INFO},  LOG_DEBUG = ${
				process.env.LOG_DEBUG
			} ...`
		);
		const developerModeService = diContainer.get(DI_TYPES.DeveloperModeService);
		console.log(
			`By default developerMode is ${
				developerModeService.isEnabled() ? 'ENABLED' : 'DISABLED'
			}`
		);
	}

	setupBugSnag = () => {
		let bugsnagReleaseStage = 'development';
		if (process.env.REACT_APP_CONSERV_HOST === 'app.conserv.io')
			bugsnagReleaseStage = 'production';
		if (process.env.REACT_APP_CONSERV_HOST === 'test.conserv.io')
			bugsnagReleaseStage = 'test';

		Bugsnag.start({
			apiKey: '4e38011ca0f2913c8b451a0e2015988a',
			plugins: [new BugsnagPluginReact()],
			releaseStage: bugsnagReleaseStage
		});
	};

	loadLocale = () => {
		const { language } = window.navigator;
		const locale = importLocale(language);
		if (locale) {
			locale.then(loc => {
				dayjs.locale(loc);
			});
		}
	};

	componentDidMount() {
		this.loadLocale();
		if (!this.state.redirectToLogin) {
			this.validateLoginState();
		}
		if (!this.state.navOnly) {
			const script = document.createElement('script');
			script.src = '//js.hs-scripts.com/4476838.js';
			script.async = true;
			document.body.appendChild(script);
		}

		// start the token validity check, every 2 minutes.
		setInterval(() => {
			this.validateLoginState();
		}, 120000);
	}

	componentDidUpdate(prevProps) {
		this.validateLoginState();
	}

	validateLoginState = () => {
		const { cookies } = this.props;
		const jwtCookie = cookies.get('jwt');
		if (jwtCookie) {
			if (!this.state.loggedIn) {
				Api.validateToken()
					.then(res => {
						if (res.data.valid) {
							this.setState(
								{ loggedIn: true, name: res.data.name },
								this.forceUpdate()
							);
							if (this.urlParams.get('redirected')) {
								window.location.href =
									decodeURI(this.urlParams.get('path')) +
									decodeURI(this.urlParams.get('query'));
							}
						} else {
							cookies.remove('jwt', { domain: '.conserv.io' });
							// if this user has an active Google Auth session, kill it
							googleLogout();

							this.loginRedirect();
						}
					})
					.catch(() => {
						this.loginRedirect();
					});
			}
		} else {
			this.loginRedirect();
		}
	};

	loginRedirect = () => {
		if (
			!window.location.pathname.includes('/login') &&
			!window.location.pathname.includes('/public/')
		) {
			window.location.href = `/login?redirected=true&path=${this.state.pathname}&query=${this.state.queryparams}`;
		}
	};

	render() {
		const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
		if (this.state.redirectToLogin) {
		} else {
			return (
				<InversifyProvider container={diContainer}>
					<GoogleOAuthProvider
						clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
					>
						<QueryClientProvider client={queryClient}>
							<ReactQueryDevtools initialIsOpen={false} />
							<ErrorBoundary>
								<Router history={history}>
									<CookiesProvider>
										<StoreContext.Provider value={store}>
											<ThemeProvider theme={theme}>
												<ThemeProviderV4 theme={themev4}>
													<Box display='flex' flexGrow={1}>
														{this.state.loggedIn &&
														!window.location.pathname.includes('/public/') ? (
															<PreferencesProvider>
																<PermissionProvider value={{}}>
																	<Nav name={this.state.name} />
																</PermissionProvider>
															</PreferencesProvider>
														) : (
															<PublicRoutes onLogin={this.validateLoginState} />
														)}
													</Box>
												</ThemeProviderV4>
											</ThemeProvider>
										</StoreContext.Provider>
									</CookiesProvider>
								</Router>
							</ErrorBoundary>
						</QueryClientProvider>
					</GoogleOAuthProvider>
				</InversifyProvider>
			);
		}
	}
}

export default withErrorHandler(withCookies(App));
