import React, { Component } from 'react';
import {
	Box,
	Button,
	TextField,
	InputAdornment,
	Link,
	Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Icon } from '@mdi/react';
import { passwordStrength } from 'check-password-strength';
import { connectStoreon } from 'storeon/react';

import Api from '../../api/Api';
import {
	buttonStyles,
	passwordStyles,
	formValidationStyles,
	loginStyles
} from '../../style/styles';
import icons from '../../style/icons';
import { colors } from '../../style/colors';

const useStyles = () => ({
	...buttonStyles,
	...passwordStyles,
	...formValidationStyles,
	...loginStyles
});

class PasswordReset extends Component {
	constructor(props) {
		super(props);
		this.state = {
			formValidation: { passValid: false },
			password: '',
			retryPassword: ''
		};
	}

	classes = this.props.classes;

	handlePasswordChange = (password, otherPassword, isOtherPassword) => {
		const formValidation = this.validatePassword(password);
		if (password !== otherPassword) {
			formValidation.passwordError = 'New Passwords must match';
			formValidation.passValid = false;
		}
		if (isOtherPassword) {
			this.setState({
				...this.state,
				retryPassword: password,
				formValidation
			});
		} else {
			this.setState({
				...this.state,
				password,
				formValidation
			});
		}
	};

	validatePassword = password => {
		const formValidation = { passValid: true };
		if (!password) {
			formValidation.passwordError = 'Password cannot be blank';
			formValidation.passValid = false;
		} else {
			const strength = passwordStrength(password);
			if (!strength || strength?.id < 1) {
				formValidation.passwordError =
					'Password must be at least six characters, upper and lower case';
				formValidation.passValid = false;
			}
		}
		return formValidation;
	};

	sendReset = () => {
		Api.resetPassword(this.state.password, this.props.token)
			.then(() => {
				// switch to the login view once the password is reset
				this.props.onReset();
			})
			.catch(error => {
				this.props.onError({
					message: (
						<>
							<div>There was an error resetting your password, please</div>
							<Link
								classes={{ root: this.classes.errorLink }}
								href='https://support.conserv.io/knowledge/kb-tickets/new'
								target='new'
							>
								contact Conserv support
							</Link>
						</>
					),
					status: error.response.status
				});
			});
	};

	togglePasswordVisibility = () => {
		this.setState({ showpassword: !this.state.showpassword });
	};

	closeDialog = () => {
		this.setState({ redirectToLogin: true });
	};

	render() {
		return (
			<Box>
				<Box
					pl={9}
					pr={9}
					bgcolor={`${colors.white.primary}`}
					border=''
					borderRadius='4px'
					borderTop={`solid 16px ${colors.purpleHearth.primary}`}
					width='680px'
				>
					<Box pb={2} pt={4}>
						<Typography
							classes={{ root: this.props.classes.loginTitle }}
							variant='h1'
						>
							{this.props.from === 'welcome'
								? 'Set Password'
								: 'Change Password'}
						</Typography>
					</Box>
					<Box mb={4}>
						<Typography classes={{ root: this.props.classes.resetText }}>
							In order to protect your account, please make sure your password
							contains:{' '}
						</Typography>
						<ul>
							<li>
								<Typography classes={{ root: this.props.classes.resetText }}>
									at least 6 characters upper and lower case
								</Typography>
							</li>
							<li>
								<Typography classes={{ root: this.props.classes.resetText }}>
									at least 1 special characters such as !@#$%^&*()-_
								</Typography>
							</li>
						</ul>
					</Box>
					<Box pb={3}>
						<TextField
							data-cy='password'
							id='password'
							variant='outlined'
							error={Boolean(this.state.formValidation.passwordError)}
							helperText={this.state.formValidation.passwordError}
							InputProps={{
								classes: {
									input: this.props.classes.input
								},
								endAdornment: (
									<InputAdornment postiion='end'>
										<Icon
											color={`${colors.black.mediumOpacity}`}
											size={1}
											path={this.state.showPass ? icons.hide : icons.show}
											onClick={() =>
												this.setState({ showPass: !this.state.showPass })
											}
										/>
									</InputAdornment>
								)
							}}
							placeholder='Enter new password'
							type={this.state.showPass ? 'text' : 'password'}
							value={this.state.password}
							onChange={ev =>
								this.handlePasswordChange(
									ev.target.value,
									this.state.retryPassword
								)
							}
							label='New Password'
							InputLabelProps={{ shrink: true }}
						/>
					</Box>
					<Box pb={4}>
						<TextField
							data-cy='password-repeat'
							id='password-repeat'
							variant='outlined'
							error={Boolean(this.state.formValidation.passwordError)}
							helperText={this.state.formValidation.passwordError}
							InputProps={{
								classes: {
									input: this.props.classes.input
								},
								endAdornment: (
									<InputAdornment postiion='end'>
										<Icon
											color={`${colors.black.mediumOpacity}`}
											size={1}
											path={this.state.showPass ? icons.hide : icons.show}
											onClick={() =>
												this.setState({ showPass: !this.state.showPass })
											}
										/>
									</InputAdornment>
								)
							}}
							placeholder='Enter new password'
							type={this.state.showPass ? 'text' : 'password'}
							value={this.state.retryPassword}
							onChange={ev =>
								this.handlePasswordChange(
									ev.target.value,
									this.state.password,
									true
								)
							}
							label='New Password'
							InputLabelProps={{ shrink: true }}
						/>
					</Box>
					<Box display='flex' justifyContent='center' pb={5}>
						<Button
							color='primary'
							data-cy='reset-password'
							variant='contained'
							disabled={!this.state.formValidation.passValid}
							onClick={this.sendReset}
							classes={{ root: this.props.classes.singInButton }}
						>
							{this.props.from === 'welcome'
								? 'Set Password'
								: 'Change Password'}
						</Button>
					</Box>
				</Box>
			</Box>
		);
	}
}

export default connectStoreon(
	withStyles(useStyles, { withTheme: true })(PasswordReset)
);
