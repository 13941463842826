import { Component } from 'react';
import { Box, Link, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import QueryString from 'query-string';
import { withCookies, Cookies } from 'react-cookie';

import { instanceOf } from 'prop-types';
import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import Api from '../../api/Api';
import Login from './Login';
import SignIn from '../../../pages/SignIn';
import PasswordReset from './PasswordReset';
import AcceptInvite from './AcceptInvite';
import SegmentUtil from '../../util/SegmentUtil';
import LoginHelp from './LoginHelp';
import { PreferencesContext } from '../../providers/PreferencesProvider';
import {
	loginStyles,
	formValidationStyles,
	linkStyles
} from '../../style/styles';
import DividerWithText from '../common/elements/DividerWithText';

import GoogleButton from '../../../components/GoogleButton';

const url = `https://${window.location.host}/api/auth/appleoauth`;

const useStyles = () => ({
	...loginStyles,
	...formValidationStyles,
	...linkStyles,
	loginContainer: {
		backgroundImage: 'url("/img/photos/login_background.jpg")',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundAttachment: 'fixed',
		backgroundPosition: 'left',
		backgroundOrigin: 'content-box',
		width: '100%'
	},
	nounderline: {
		'&::before': {
			borderBottom: 'none'
		},
		'&::after': {
			borderBottom: 'none'
		},
		'&:hover:not(.Mui-disabled):before': {
			borderBottom: 'none'
		}
	}
});

class Entry extends Component {
	static contextType = PreferencesContext;

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);

		const values = QueryString.parse(this.props.location.search);

		this.state = {
			email: values.email ? decodeURIComponent(values.email) : '',
			pass: '',
			error: null,
			loggedIn: false,
			landingUrl: '/',
			resetDialogOpen: false,
			register: props.location.pathname.includes('register'),
			// register: false,
			reset: props.location.pathname.includes('passwordreset'),
			acceptInvite: props.location.pathname.includes('acceptinvite'),
			token: values.token,
			referralcode: this.props.match?.params?.referralcode,
			surveyEmail: '',
			from: values.from ?? ''
		};
	}

	googleLoginError = () => {
		this.setState({
			error: { message: 'There has been an error authenticating with Google' }
		});
		Bugsnag.notify(
			new Error('There has been an error authenticating with Google')
		);
	};

	loginWithGoogle = (user, token) => {
		const { cookies } = this.props;
		Api.loginoauth(user, token)
			.then(res => {
				if (res.data.token) {
					const { firstlogin } = res.data;
					const expiry = new Date();
					expiry.setDate(new Date().getDate() + 30);
					cookies.set('jwt', res.data.token, {
						domain: '.conserv.io',
						path: '/',
						secure: false,
						expires: expiry
					});

					cookies.set('cuser', {
						email: res.data.email,
						first_name: res.data.first_name,
						last_name: res.data.last_name,
						role: res.data.role,
						customerId: res.data.customerId,
						uuid: res.data.uuid
					});
					SegmentUtil.identifyAndGroup(res.data);
					Api.getPreferences()
						.then(res => {
							const preferences = res.data;
							this.context.updatePreferences(preferences);
						})
						.catch(() => {
							// A 401 here is expected
						});
					this.setState({
						error: false,
						loggedIn: true,
						landingurl: '/'
					});
				} else {
					// handle a no token case?
					this.setState({
						error: {
							message: 'There has been an error authenticating with Google'
						}
					});
				}
			})
			.catch(() => {
				this.setState({
					error: {
						message: 'There has been an error authenticating with Google'
					}
				});
			});
	};

	handleError = error => {
		this.setState({ error });
	};

	handleReset = () => {
		this.setState({ reset: false });
		// for some reason react router redirects are resulting in a blank page, so just point the browser instead
		if (this.state.loggedIn) {
			window.location.href = '/';
		} else {
			window.location.href = '/login';
		}
	};

	handleForgotPassword = () => {
		this.setState({ resetDialogOpen: true });
	};

	submitHubspotForm = () => {
		axios(
			'https://api.hsforms.com/submissions/v3/integration/submit/4476838/1c7132a6-9431-4a0e-b99e-8d670b4c5b8f',
			{
				method: 'post',
				data: { fields: [{ name: 'email', value: this.state.surveyEmail }] },
				withCredentials: false
			}
		)
			.then(result => {
				const newUri = result.data.redirectUri;
				window.open(newUri, '_blank');
			})
			.catch(() => {
				alert('There was an error opening the registration process...');
			});
	};

	render() {
		const { classes } = this.props;
		if (this.state.loggedIn) {
			window.location.href = this.state.landingurl;
			return null;
		}
		return (
			<>
				<Box
					display='flex'
					flexDirection='row'
					justifyContent='space-around'
					alignItems='center'
					width='100%'
					bgcolor={this.state.reset ? '#E5E5E5' : ''}
				>
					{this.state.acceptInvite ? (
						<AcceptInvite
							token={this.state.token}
							referralcode={this.state.referralcode}
							onError={this.handleError}
						/>
					) : null}
					{!this.state.reset && !this.state.acceptInvite ? (
						<Box
							classes={{ root: classes.ctaPanel }}
							display='flex'
							flexDirection='column'
							alignItems='center'
							justifyContent='center'
						>
							<Box
								display='flex'
								flexDirection='row'
								p={2}
								width='100%'
								justifyContent='center'
							>
								<img
									alt='conserv logo'
									style={{ width: '36%' }}
									src='/img/conserv_io_new_logo_white.png'
								/>
							</Box>
						</Box>
					) : null}
					{!this.state.acceptInvite ? (
						<Box
							height='100%'
							width='50%'
							display='flex'
							flexDirection='column'
							alignItems='center'
							justifyContent='center'
						>
							<Box
								classes={{
									root: !this.state.reset ? classes.loginPanel : null
								}}
								display='flex'
								flexDirection='column'
								justifyContent='center'
							>
								{this.state.error ? (
									this.state.error.status < 500 &&
									this.state.error.status != 404 ? (
										<Box
											classes={{ root: classes.errorBox }}
											data-cy='error-dialog'
											pb={2}
											mb={2}
										>
											{this.state.error.message}
										</Box>
									) : (
										<Alert
											variant='outlined'
											severity='warning'
											style={{
												border: '1px solid orange',
												borderRadius: '4px',
												display: 'flex',
												alignItems: 'center',
												justifyContent: 'center',
												padding: '8px 16px',
												margin: '8px 0'
											}}
										>
											<Typography
												variant='body2'
												style={{
													fontFamily: 'Roboto',
													fontSize: '14px',
													fontWeight: 400,
													lineHeight: '20px',
													letterSpacing: '0.17000000178813934px'
												}}
											>
												We're experiencing technical difficulties. We're
												actively addressing the issue and appreciate your
												patience.{' '}
												<Link
													href='#'
													onClick={() => {
														window.open(
															'https://community.conserv.io',
															'_blank'
														);
													}}
												>
													Join our community
												</Link>{' '}
												for updates.
											</Typography>
										</Alert>
									)
								) : null}
								{!this.state.reset ? (
									<>
										<Box mb={'4px'}>
											<Typography
												variant='h1'
												classes={{ root: classes.loginTitle }}
											>
												{this.state.register
													? 'Create an organization account'
													: 'Log in'}
											</Typography>
										</Box>
										{this.state.register ? null : (
											<Box mb={2}>
												<Typography
													classes={{ root: classes.newToConservSubTitle }}
												>
													To our environmental monitoring platform
												</Typography>
											</Box>
										)}
										<Box display='flex' mb='27px'>
											<Box>
												{this.state.register ? (
													<Typography
														classes={{ root: classes.newToConservText }}
													>
														Already have an account?{' '}
														<Link
															classes={{ root: classes.newToConservLink }}
															href='#' // Replace with your actual link
															onClick={() => this.setState({ register: false })}
														>
															Log in
														</Link>{' '}
													</Typography>
												) : (
													<Typography
														classes={{ root: classes.newToConservText }}
													>
														New to Conserv? Fill out{' '}
														<Link
															classes={{ root: classes.newToConservLink }}
															href='https://conserv.io/free-conserv-cloud-software/' // Replace with your actual link
															target='_blank'
														>
															this form
														</Link>{' '}
														and create an account. An organization account can
														have multiple users.
													</Typography>
												)}
											</Box>
										</Box>
									</>
								) : null}
								<>
									{!this.state.reset ? (
										<>
											<Box>
												{this.state.register ? (
													<Box mb={4}>
														<SignIn />
													</Box>
												) : (
													<Login
														email={this.state.email}
														onError={this.handleError}
														onLogin={this.props.onLogin}
														onForgotPassword={this.handleForgotPassword}
													/>
												)}
											</Box>
											<Box mb={4}>
												<DividerWithText>OR</DividerWithText>
											</Box>
											<Box pr={1} mb={4} display='flex' justifyContent='center'>
												<GoogleButton
													onSuccess={this.loginWithGoogle}
													onFailure={this.googleLoginError}
												/>
											</Box>
											<Box>
												<Typography
													classes={{ root: classes.termsAndConditionText }}
												>
													By using Conserv’s products and services, you agree to
													our{' '}
													<Link
														style={{ textDecoration: 'none', fontWeight: 700 }}
														href='https://docs.google.com/document/d/1X76q31gWKK_YuokYNffqmovf0rzCTE7GqtCx3juDg40/edit#heading=h.fpva18fi05fu'
														target='_new'
													>
														Terms and Conditions
													</Link>{' '}
													and{' '}
													<Link
														style={{ textDecoration: 'none', fontWeight: 700 }}
														href='https://docs.google.com/document/d/1X76q31gWKK_YuokYNffqmovf0rzCTE7GqtCx3juDg40/edit#heading=h.4xzpd2cjrm41'
														target='_new'
													>
														Privacy Policy
													</Link>
													.{' '}
												</Typography>
											</Box>
										</>
									) : (
										<PasswordReset
											onReset={this.handleReset}
											token={this.state.token}
											onError={this.handleError}
											from={this.state.from}
										/>
									)}
								</>
							</Box>
						</Box>
					) : null}
				</Box>
				<LoginHelp
					open={this.state.resetDialogOpen}
					onClose={() => this.setState({ resetDialogOpen: false })}
				/>
			</>
		);
	}
}

export default withCookies(withStyles(useStyles, { withTheme: true })(Entry));
